import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row, Image } from "react-bootstrap";
import { faAngleRight, faHeart as solidHeart, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useState, useEffect, Fragment } from "react";
import HTTP from "../../../config/axios";
import { useParams } from 'react-router-dom';
import MultiCarousel from "../../../components/common/MultiCarousal";
import { convertStringToSlug, getOriginalUrlFromThumb, calculateEmi, parsePriceToFloat, ordinalSuffixForOwner, VehicleSaleStatus, insuranceType } from "../../../config/utils/helper";
import { useNavigate, useLocation } from "react-router-dom";
import { handleAddToCompare } from "../../../config/utils/helper";
import ContactUs from "../../../components/common/ContactUs";
import SimilarCars from "../../../components/modules/Vehicle/SimilarCars";
import DamageReport from "../../../components/modules/Vehicle/DamageReport";
import SpecificationList from "../../../components/modules/Vehicle/SpecificationList";
import FeaturesList from "../../../components/modules/Vehicle/FeaturesList";
import CustomModal from "../../../components/common/CustomModal";
import SimpleToast from "../../../components/common/Toast";
import { encryptLocalStorage } from "../../../config/utils/storage";
import TestDrive from './TestDrive'
import BasicEmiCalculator from "../../../components/common/BasicEmiCalculator";
import { getFormatMMMYYYY, getFormatyyyymmdd } from "../../../config/utils/dateFunctions";
import SimpleSpinner from "../../../components/common/Spinner";
import VehicleMainImage from "../../../components/common/VehicleMainImage";
import PopularBenefits from "../../common/PopularBenefits";


const CarDetails = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const [details, setDetails] = useState<any>({ make: '', model: '', year: '', variant: '' });
    const [mainImage, setMainImage] = useState("");
    const [isFavorite, setIsFavorite] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showTestDriveModal, setShowTestDriveModal] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false)
    const { VEHICLE_SOLD_STATUS, VEHICLE_BOOKED_STATUS, VEHICLE_SELL_STATUS } = VehicleSaleStatus
    const [isScrolling, setIsScrolling] = useState(false);
    const [addCounter, setAddCounter] = useState(false)


    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 565);
    }, []);

    useEffect(() => {
        const getCarDetails = async () => {
            setIsLoading(true)
            const response = await HTTP.get(`/api/vehicle/fetch-one/${vehicleId}`);
            const { data: { status, data, msg, error } } = response
            if (status == 0) {
                setIsLoading(false)
                navigate('/buy-used-car')
            }
            if (status == 1) {
                setIsLoading(false)
                setDetails(data)
                const carMainImage = getOriginalUrlFromThumb(`${process.env.REACT_APP_CLOUDFRONT_URL}${data.image}`)
                setMainImage(carMainImage)
            } else {
                setIsLoading(false)
            }
        }
        getCarDetails()
    }, [window.location.pathname]);

    useEffect(() => {
        const getFavourite = async () => {
            const response = await HTTP.get(`/api/vehicle/fetch-single-favourite/${vehicleId}`);
            const { data: { status, data, msg, error } } = response
            if (status == 1 && data.length != 0) {
                setIsFavorite(true)
            } else {
                setIsFavorite(false)
            }
        }
        isLoggedIn && getFavourite()
    }, [isLoggedIn, window.location.pathname]);

    useEffect(() => {
        const popularCounter = async () => {
            const response = await HTTP.get(`/api/vehicle/update-count/${vehicleId}`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setAddCounter(true)
            } else {
                setAddCounter(false)
            }
        }
        popularCounter()
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Check if the user has scrolled, for example, more than 100 pixels
            const scrollPosition = window.scrollY;
            if (scrollPosition > 100) {
                setIsScrolling(true);
            } else {
                setIsScrolling(false);
            }
        };
        // Add scroll event listener when component mounts
        window.addEventListener('scroll', handleScroll);
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleCarouselImageClick = (imageUrl: string) => {
        const image = getOriginalUrlFromThumb(imageUrl);
        setMainImage(image);
    };

    const handleNotFavorite = async () => {
        if (isLoggedIn) {
            try {
                const response = await HTTP.post(`/api/vehicle/remove-favourites`, { vehicleId: vehicleId });
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setIsFavorite(false)
                    setToast({ open: true, header: "Success", message: "Removed from favourites successfully", variant: "success" })
                }
            } catch (error) {
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
            }
        } else {
            // window.location.href = "/sign-in"
            encryptLocalStorage.setItem("previousLoggedoutLocation", location.pathname)
            navigate('/sign-in')
        }
    }
    const handleFavorite = async () => {
        if (isLoggedIn) {
            try {
                const response = await HTTP.post(`/api/vehicle/add-favourites`, { vehicleId: vehicleId });
                const { data: { status, data, message, error } } = response
                if (status == 1) {
                    setIsFavorite(true);
                    setToast({ open: true, header: "Success", message: "Added to favourites successfully", variant: "success" })
                } else {
                    setToast({ open: true, header: "Failed", message: message, variant: "danger" })
                }
            } catch (error) {
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
            }

        } else {
            // window.location.href = "/sign-in"
            encryptLocalStorage.setItem("previousLoggedoutLocation", location.pathname)
            navigate('/sign-in')
        }
    }

    const handleTestDrive = () => {
        if (isLoggedIn) {
            setShowTestDriveModal(true)
        } else {
            encryptLocalStorage.setItem("previousLoggedoutLocation", location.pathname)
            navigate('/sign-in')
        }
    }
    const handleBooking = () => {
        encryptLocalStorage.setItem("previousLoggedoutLocation", location.pathname)
        navigate('/sign-in')
    }

    const formattedPrice = (price: any) => {
        let priceFormatted = "";
        let str = ""
        if (price < 1000) {
            priceFormatted = price
        } else if (price >= 1000 && price < 100000) {
            str = parsePriceToFloat(price, 1000, 2)
            priceFormatted = str + " K";
        } else if (price >= 100000 && price < 10000000) {
            str = parsePriceToFloat(price, 100000, 2)
            priceFormatted = str + " Lakhs"
        } else {
            str = parsePriceToFloat(price, 10000000, 2)
            priceFormatted = str + " Crore";
        }
        return priceFormatted;
    };

    const fullCarName = `${details.year || ''} ${details.make} ${details.model}`
    const carSlugName = `${details.year || ''} ${details.make} ${details.model} ${details.variant}`
    const slugCarName = convertStringToSlug(carSlugName);

    const handleShareCar = () => {
        const url = window.location.href;
        const tempInput = document.createElement('input');
        tempInput.value = url;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        setToast({ open: true, header: "Copied Link", message: "Link copied to the clipboard", variant: "success" })
    }

    const verifyAndCompare = (id: string, slugCarName: any) => {
        const compareList = encryptLocalStorage.getItem("compareCar");

        // if (compareList && compareList.length >= 4) {
        //     setToast({ open: true, header: 'Failed', message: 'Car compare limit reached', variant: 'danger' })
        // }

        if ((compareList?.[0]?.id == id) || (compareList?.[1]?.id == id) ||
            (compareList?.[2]?.id == id) || (compareList?.[3]?.id == id)) {
            setToast({ open: true, header: 'Failed', message: 'Car is already in compare list', variant: 'danger' })
        } else {
            handleAddToCompare(id, slugCarName, navigate, location?.state?.position, isMobile)
        }
    }

    const handle360View = () => {
        window.open('/image-view-360', '_blank')
    }

    const setBadgeForImage = (saleStatus: number) => {
        if (saleStatus == VEHICLE_SOLD_STATUS) {
            return ("sold")
        } else if (saleStatus == VEHICLE_BOOKED_STATUS) {
            return ("booked")
        } else {
            return ("")
        }
    }

    const getInsuranceTypeName = (id: any) => {
        const insuranceTypeMap = insuranceType.find(type => type.id == id);
        return insuranceTypeMap ? insuranceTypeMap.name : '-';
    };
    return (
        <>
            <Container className="pt-2 mt-60">
                <Row className={isMobile ? "my-2 car-details" : "my-5 car-details"}>
                    {isMobile &&
                        <Fragment>
                            <div className={`${isScrolling ? 'sticky-wrapper pt-2' : 'mt-5'}`}>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <h2 className="text-dark pe-3 is-testimonials mb-0">{fullCarName || ''} <span className="is-testimonials ">{details.variant} </span></h2>
                                    <div className="d-flex align-items-center">
                                        <span onClick={() => verifyAndCompare(vehicleId as string, slugCarName)} style={{ cursor: "pointer" }} className="d-flex align-items-center pe-4">
                                            <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/compare.svg`} width={"30px"} />
                                        </span>
                                        <div className="pt-1 fs-4 pe-4">
                                            <FontAwesomeIcon icon={faShareNodes} style={{ cursor: "pointer" }} onClick={handleShareCar} title="Share vehicle link" />
                                        </div>
                                        <div className="pt-1 fs-4" style={{ cursor: "pointer" }}>
                                            {/* {isFavorite && <FontAwesomeIcon icon={solidHeart} onClick={handleNotFavorite} className="text-primary" title="Remove from favourites" />}
                                            {!isFavorite && <FontAwesomeIcon icon={regularHeart} onClick={handleFavorite} title="Add to favourites" />} */}
                                            {isFavorite ? <FontAwesomeIcon icon={solidHeart} onClick={handleNotFavorite} className="text-primary" title="Remove from favourites" />
                                                :
                                                <FontAwesomeIcon icon={regularHeart} onClick={handleFavorite} title="Add to favourites" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                    <Col lg={7} md={12} className="border-end border-end-sm-0 pb-4 has-custom-arrow-carousel">
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            {/* <Image src={mainImage} width={"100%"} className="mb-3" /> */}
                            <VehicleMainImage image={mainImage} badge={setBadgeForImage(details.saleStatus)} isDealOfTheDay={details.isDealOfTheDay} isAssuredStatus={details.assuredStatus} />
                            {/* TEmp code: need change later */}
                            {vehicleId == 'a8784040-8eb2-11ee-ac0e-a3802d150afb' && <div className={!isMobile ? "image-badge-web" : "image-badge-mobile"} >
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/360-badge.png`} width={"40%"} onClick={handle360View} style={{ cursor: "pointer" }} />
                            </div>}
                        </div>
                        <MultiCarousel id={vehicleId as string} onCarouselImageClick={handleCarouselImageClick} detailPage={true} isMobile={isMobile} />

                    </Col>
                    <Col lg={5} md={12} className="ps-3">
                        {!isMobile ? (
                            <Fragment>
                                <div className="d-flex text-dark align-items-center">
                                    <div className="me-auto pe-3">
                                        <h2 className="is-testimonials mb-0">{fullCarName || ''} <span className="is-testimonials ">{details.variant} </span></h2>
                                         
                                    </div>
                                    <div className="pt-1 fs-4 pe-5">
                                        <FontAwesomeIcon icon={faShareNodes} style={{ cursor: "pointer" }} onClick={handleShareCar} title="Share vehicle link" />
                                    </div>
                                    <div className="pt-1 fs-4" style={{ cursor: "pointer" }}>
                                        {isFavorite && <FontAwesomeIcon icon={solidHeart} onClick={handleNotFavorite} className="text-primary" title="Remove from favourites" />}
                                        {!isFavorite && <FontAwesomeIcon icon={regularHeart} onClick={handleFavorite} title="Add to favourites" />}
                                    </div>
                                </div>

                                <div className="d-flex align-self-center align-items-center mt-4" title="Add to compare">
                                    <span onClick={() => verifyAndCompare(vehicleId as string, slugCarName)} style={{ cursor: "pointer" }} className="d-flex align-items-center">
                                        <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/compare.svg`} width={"30px"} />
                                        <h3 className="mb-0 ps-2 text-primary" >Add to compare</h3>
                                    </span>
                                </div>
                            </Fragment>) : (
                            <hr />
                        )}
                        <div className="d-flex justify-content-between pt-3 is-description">
                            <div>{details.km} Km</div>
                            <div>{details.fuel}</div>
                            <div>{details.transmission}</div>
                        </div>
                        <h3 className="pt-3">Home Test Drive: {details.testDrive == 1 ? `Available within 5 Kms` : `Not Available`}</h3>
                        <h3 className="py-2">{details.address}</h3>
                        <hr />
                        <h3 className="text-primary mb-0">
                            <Link to='/benefits'>View benefits <FontAwesomeIcon icon={faAngleRight} /></Link>
                        </h3>
                        <div className="d-flex justify-content-between pt-3 is-description">
                            <h3>
                                {" "}
                                <span className="text-primary">Guaranteed Buyback:</span> Return
                                this car to us within a tenure of 6, 12 or 18 months at
                                guaranteed prices.
                            </h3>
                            <FontAwesomeIcon
                                className="ps-5 pt-2 text-primary"
                                icon={faAngleRight}
                            />
                        </div>
                        <hr />
                        <h3>
                            <span className="text-primary is-testimonials"> ₹{formattedPrice(details.isDealOfTheDay == 1 && details.dealPrice?details.dealPrice:details.price)}</span>{" "}
                            {details.isDealOfTheDay == 1 && details.dealPrice && <del className="fs-6 p-2 text-secondary">₹{formattedPrice(details.price)}</del>}
                            with EMI{" "}
                            <span className="text-primary is-description">₹{calculateEmi(details.isDealOfTheDay == 1 && details.dealPrice?details.dealPrice:details.price)}/month</span>
                        </h3>
                        <h3 className="mb-4">Fixed on road price</h3>
                        {details.saleStatus == VEHICLE_SELL_STATUS &&
                            <Row>
                                <Col lg={5} md={6} sm={6} xs={6} className=" mb-3">
                                    {isLoggedIn ? (<Link to="/booking" state={{ fullCarName, vehicleId, price: details.isDealOfTheDay == 1 && details.dealPrice?details.dealPrice:details.price }} className="btn btn-primary w-100">
                                        Book&nbsp;now
                                    </Link>) : (<Button variant="primary w-100" type="button" onClick={() => handleBooking()} >Book&nbsp;now</Button>)}
                                </Col>
                                <Col lg={5} md={6} sm={6} xs={6} className="mb-3">
                                    {details.testDrive === 1 && <Button variant="outline-primary w-100" type="button" onClick={() => handleTestDrive()} >
                                        Free&nbsp;test&nbsp;drive
                                    </Button>}
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
                {!isMobile ? (
                    <>
                        <Row >
                            <PopularBenefits />
                            <Col lg={6} md={12} sm={12}>
                                <h3 className="text-dark is-testimonials mt-2">Car overview</h3>
                                <div className="border rounded px-3">
                                    <Row className="pt-2">

                                        <Col md="4">
                                            <p>Make Year</p>
                                            <p className="text-dark is-description"> {details.yearMonthManufacture?getFormatMMMYYYY(details.yearMonthManufacture):"-"}</p>
                                        </Col>
                                        <Col md="4">
                                            <p>Registration Year</p>
                                            <p className="text-dark is-description"> {details.yearMonthRegistration?getFormatMMMYYYY(details.yearMonthRegistration):"-"}</p>
                                        </Col>
                                        <Col md="4">
                                            <p>Fuel type</p>
                                            <p className="text-dark is-description">{details.fuel ? details.fuel:"-"}</p>
                                        </Col>
                                    </Row>

                                    <Row className="border-top pt-2">
                                        <Col md="4">
                                            <p>KM Driven</p>
                                            <p className="text-dark is-description">
                                            {details.km ? `${details.km} Kms` : "-"}
                                            </p>
                                        </Col>
                                        <Col md="4">
                                            <p>Transmission</p>
                                            <p className="text-dark is-description">{details.transmission ? details.transmission:"-"}</p>
                                        </Col>
                                        <Col md="4">
                                            <p>No.of owners</p>
                                            <p className="text-dark is-description">{details.numberOfOwners?`${ordinalSuffixForOwner(details.numberOfOwners)} owner`:"-"} </p>
                                        </Col>
                                    </Row>

                                    <Row className="border-top pt-2">
                                        <Col md="4">
                                            <p>Insurance Validity</p>
                                            <p className="text-dark is-description">{details.insuranceValidity ? getFormatMMMYYYY(details.insuranceValidity) : '-'}</p>
                                        </Col>
                                        <Col md="4">
                                            <p>Insurance Type</p>
                                            <p className="text-dark is-description">{details.insuranceType ? getInsuranceTypeName(details.insuranceType) : "-"}</p>
                                        </Col>
                                        <Col md="4">
                                            <p>RTO</p>
                                            <p className="text-dark is-description">{details.rto || "-"}</p>
                                        </Col>
                                    </Row>
                                    <Row className="border-top pt-2">
                                        <Col md="4">
                                            <p>Car Location</p>
                                            <p className="text-dark is-description">{details.outletName?details.outletName:"-"}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <SpecificationList id={vehicleId} isLimited={true} />
                                <FeaturesList id={vehicleId} />
                            </Col>
                            <Col lg={6} md={6} sm={6} className="">
                                <DamageReport id={vehicleId} report={"detail"} />
                                {details.id && <BasicEmiCalculator price={details.isDealOfTheDay == 1 && details.dealPrice?details.dealPrice:details.price} />}
                            </Col>
                        </Row>
                        {/* {isLoggedIn && 
                    <Row >
                        <CarDentMap id={vehicleId} />
                    </Row>
                    } */}
                    </>) : (
                    <>
                        <PopularBenefits />
                        <Row className="p-3">
                        
                            <h3 className="text-dark is-testimonials p-0">Car Overview</h3>
                            <div className="border rounded p-3">
                                <Row>
                                    <Col sm="6" xs="6">
                                        <p className="mb-0">Make Year</p>
                                        <p className="text-dark is-description">
                                            {" "}
                                                {details.yearMonthManufacture?getFormatMMMYYYY(details.yearMonthManufacture):"-"}
                                        </p>
                                    </Col>
                                    <Col sm="6" xs="6">
                                        <p className="mb-0">Registration Year</p>
                                        <p className="text-dark is-description">
                                            {" "}
                                                {details.yearMonthRegistration?getFormatMMMYYYY(details.yearMonthRegistration):"-"}
                                        </p>
                                    </Col>

                                    <hr />

                                    <Col sm="6" xs="6">
                                        <p className="mb-0">Fuel Type</p>
                                            <p className="text-dark is-description">{details.fuel ? details.fuel:"-"}</p>
                                    </Col>
                                    <Col sm="6" xs="6">
                                    <p className="mb-0">KM Driven</p>
                                            <p className="text-dark is-description">
                                            {details.km ? `${details.km} Kms` : "-"}
                                            </p>
                                    </Col>

                                    <hr />

                                    <Col sm="6" xs="6">
                                        <p className="mb-0">Transmission</p>
                                        <p className="text-dark is-description">
                                                {details.transmission ? details.transmission:"-"}
                                        </p>
                                    </Col>

                                    <Col sm="6" xs="6">
                                        <p className="mb-0">No.of owners</p>
                                        <p className="text-dark is-description">{details.numberOfOwners?`${ordinalSuffixForOwner(details.numberOfOwners)} owner`:"-"} </p>
                                    </Col>

                                    <hr />

                                    <Col sm="6" xs="6">
                                        <p className="mb-0">Insurance Validity</p>
                                        <p className="text-dark is-description">{details.insuranceValidity ? getFormatMMMYYYY(details.insuranceValidity) : '-'}</p>
                                    </Col>

                                    <Col sm="6" xs="6">
                                        <p className="mb-0">Insurance Type</p>
                                        <p className="text-dark is-description">{details.insuranceType ? getInsuranceTypeName(details.insuranceType) : "-"}</p>
                                    </Col>

                                    <hr />

                                    <Col sm="6" xs="6">
                                        <p className="mb-0">RTO</p>
                                        <p className="text-dark is-description">{details.rto || "-"}</p>
                                    </Col>

                                    <Col sm="6" xs="6">
                                        <p className="mb-0">Car Location</p>
                                            <p className="text-dark is-description">{details.outletName ? details.outletName:"-"}</p>
                                    </Col>
                                </Row>
                            </div>
                            <DamageReport id={vehicleId} report={"detail"} />
                            {/* {!isLoggedIn ? (< Button className="btn btn-primary w-50 mx-2 my-4" type="button" onClick={() => openSignInPage()} >
                                View full details
                            </Button>) : (< Button className="btn btn-primary w-50 mx-2 my-4" type="button">
                                View full details
                            </Button>)} */}
                            <SpecificationList id={vehicleId} isMobile isLimited={true} />
                            <FeaturesList id={vehicleId} isMobile />
                                {details.id && <BasicEmiCalculator price={details.isDealOfTheDay == 1 && details.dealPrice?details.dealPrice:details.price} />}
                            {/* {isLoggedIn && <Row >
                                <CarDentMap id={vehicleId} />
                            </Row>} */}
                        </Row>
                    </>
                    
                )}
            </Container >
            {details.id && <SimilarCars budget={details.price} vehicleId={details.id} bodyType={details.bodyTypeName} />}
            < ContactUs />
            <CustomModal isOpen={showTestDriveModal} title="Test Drive"
                component={<TestDrive vehicleId={vehicleId as string}
                    setShowTestDriveModal={setShowTestDriveModal}
                    setToast={setToast} />}
                closeModal={setShowTestDriveModal} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
            <SimpleSpinner show={isLoading} />
        </>
    );
}

export default CarDetails;
