import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, Col, Row } from "react-bootstrap"
import DropDownField from "../../form/DropDownField"
import { faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { downloadFileFromApi, VehicleSaleStatus } from "../../../config/utils/helper"
import { useForm } from 'react-hook-form';
import { fieldRequired } from "../../../config/validations/rules"
import DateTimePicker from "../../form/DateTimePicker"
import { startOfToday,startOfYesterday} from 'date-fns';
import MultiRadio from "../../../components/form/MultiRadio";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react"
import {getFormatyyyymmdd,addDay,differenceInDay} from "../../../config/utils/dateFunctions"
import HTTP from "../../../config/axios"
import InputTextField from "../../form/InputTextField"
import ReportTable from "./ReportDataTable"
import SimpleToast from '../../common/Toast';


const ReportCriteria  = () => {

    const { control,setValue,watch, clearErrors, getValues, handleSubmit, reset } = useForm<Record<string, any>>({ defaultValues: { report: '', saleStatus: '',vehicleStatus:'',vehicleAge:JSON.stringify({ value: 1, operator: '=' })} });
    const [maxDate, setMaxDate] = useState(getFormatyyyymmdd(startOfToday()));
    const [minDate, setMinDate] = useState(getFormatyyyymmdd(startOfYesterday()));
    const [showDateFiter, setShowDateFiler] = useState(false)
    const [showEnquiryDateFilter,setShowEnquiryDateFilter] = useState(false)
    const [vehicleAgeFiler, setVehicleAgeReport] = useState(false)
    const [showSaleStatus, setShowSaleStatus] = useState(false)
    const [showSaleLeadStatus, setShowSaleLeadStatus] = useState(false)
    const [showSoldFilter, setShowSoldFilter] = useState(false)
    const [reportData, setReportData] = useState([])
    const [reportTypes, setReportTypes] = useState('')
    const [reportFormData, setReportFormData] = useState<Record<string,any>>({})
    const [perPage, setPerPage] = useState<number>(10)
    const [years, setYears] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    
    const {VEHICLE_SELL_STATUS, VEHICLE_PRE_BOOKED_STATUS, 
            VEHICLE_BOOKED_STATUS, VEHICLE_SOLD_STATUS,
            VEHICLE_BUY_STATUS, VEHICLE_UNPUBLISH_STATUS} = VehicleSaleStatus

    //Vehicle Status
    const vehicleStatusTypeArray = [
        { keyName: '', name: "All" },
        { keyName: 1, name: "Active" },
        { keyName: 0, name: "Archived" },
    ]

    //Vehicle Sale Status
    const saleStatusTypeArray = [
        { keyName: 'all', name: "All" },
        { keyName: VEHICLE_SELL_STATUS, name: "Sell" },
        { keyName: VEHICLE_PRE_BOOKED_STATUS, name: "Pre-Booked" },
        { keyName: VEHICLE_BOOKED_STATUS, name: "Booked" },
        { keyName: VEHICLE_SOLD_STATUS, name: "Sold" },
        { keyName: VEHICLE_BUY_STATUS, name: "Purchase" },
        { keyName: VEHICLE_UNPUBLISH_STATUS, name: "Unpublished" },
    ]

    //Vehicle Sale Status
    const reportArray = [
        { keyName: 'modelWiseCount', name: "Model Wise Count Report" },
        { keyName: 'publishingAge', name: "Published Age Report" },
        { keyName: 'searchedVehicle', name: "Search Count by Vehicle Model" },
        { keyName: 'enquiryVehicle', name: "Vehicle Based Enquiry" },
        { keyName: 'soldVehicles', name: "Sold Vehicles" },
    ]

    //Vehicle Published Age
    const carAgeOptions = [
        { label: '1 Month', value: JSON.stringify({ value: 1, operator: '<=' })},
        { label: '2 Month', value: JSON.stringify({ value: 2, operator: '<=' })},
        { label: '3 Month', value: JSON.stringify({ value: 3, operator: '<=' })},
        { label: '6 Month', value: JSON.stringify({ value: 6, operator: '<=' })},
        { label: 'More than 6 month', value: JSON.stringify({ value: 7, operator: '>=' })},
    ]

    const SALE_LEADS = [
        { keyName: 10, name: "Sell" },
        { keyName: 20, name: "Buy" },
        { keyName: 30, name: "Finance" },
        { keyName: 40, name: "Premium Park & Sell" },
    ];

    useEffect(()=>{
        //Function to fetch the last 5 years
        function getLast5Years() {
            const today = new Date();
            const currentYear = today.getFullYear();
            const year = [];
        
            for (let i = 0; i < 5; i++) {
                const yearData = {
                    keyName:currentYear - i,
                    name: currentYear - i
                }
                year.push(yearData);
            }
        
            setYears(year)
        }
        getLast5Years()
    },[])

    // Define the array of objects
    const months: { keyName: number, name: string }[] = [
        { keyName: 1, name: 'January' },
        { keyName: 2, name: 'February' },
        { keyName: 3, name: 'March' },
        { keyName: 4, name: 'April' },
        { keyName: 5, name: 'May' },
        { keyName: 6, name: 'June' },
        { keyName: 7, name: 'July' },
        { keyName: 8, name: 'August' },
        { keyName: 9, name: 'September' },
        { keyName: 10, name: 'October' },
        { keyName: 11, name: 'November' },
        { keyName: 12, name: 'December' }
    ];




    //Set max date when changing the From datePicker
    useEffect(()=>{
        const slectedFromDate = watch('from')
        
        if(slectedFromDate){
            if(watch('to')){
                setMinDate(getFormatyyyymmdd(addDay(watch('to'),-1) as Date))
            }
            const days = differenceInDay(watch('from'), maxDate) as number;
            if(days < 0){
                setMaxDate(getFormatyyyymmdd(addDay(slectedFromDate,1) as Date))
            }
        }
    },[watch('from')])

    //Display the filters according to the selected report Type
    useEffect(()=>{
        //reset() //Reseting the forms when the 
        switch(watch('report')){
            case 'publishingAge':
                setShowEnquiryDateFilter(false)
                setVehicleAgeReport(true);
                setShowDateFiler(false);
                setShowSaleStatus(false);
                setValue('vehicleAge','')
                setShowSaleLeadStatus(false)
                setShowSoldFilter(false)
                break;
            case 'searchedVehicle':
                setShowEnquiryDateFilter(false)
                setShowDateFiler(true);
                setVehicleAgeReport(false);
                setShowSaleStatus(false);
                setShowSaleLeadStatus(false)
                setShowSoldFilter(false)
                break;

            case 'modelWiseCount':
                setShowEnquiryDateFilter(false)
                setShowDateFiler(false);
                setVehicleAgeReport(false);
                setShowSaleStatus(true);
                setShowSaleLeadStatus(false)
                setShowSoldFilter(false)
                break;

            case 'enquiryVehicle':
                setShowEnquiryDateFilter(true)
                setShowDateFiler(false);
                setVehicleAgeReport(false);
                setShowSaleStatus(false);
                setShowSaleLeadStatus(true)
                setShowSoldFilter(false)
                break;

            case 'soldVehicles':
                setShowEnquiryDateFilter(false)
                setShowDateFiler(false);
                setVehicleAgeReport(false);
                setShowSaleStatus(false);
                setShowSaleLeadStatus(false);
                setShowSoldFilter(true)
                break;

            default :
                setShowDateFiler(false);
                setShowEnquiryDateFilter(false)
                setVehicleAgeReport(false);
                setShowSaleStatus(false);
                setShowSaleLeadStatus(false);
                setShowSoldFilter(false)
                break;
        }

    },[watch('report')])

    //Submit 
    const onSubmit = async (formData: any) => {
        try {
            
            setReportFormData(formData);
            setCurrentPage(1);  
        }
        catch (error: any) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
    };

    useEffect(()=>{

        //Api to fetch the report Data
        const getReportData = async()=>{
            try{
                const params = reportFormData
                params['currentPage'] = currentPage
                params['perPage'] = perPage
                const response:any = await HTTP.get(`/api/admin/vehicle/report/`, { params,responseType:params.reportType != 'report'? 'blob':'json' });
                

                if(params.reportType != 'report'){
                    const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
                    downloadFileFromApi(response.data, filename)
                } else{
                    const { data: { status, data } } = response
                    if(status){
                    
                        setReportData(data)
                        setReportTypes(reportFormData.report)
    
                    } else{
                        setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })  
                    }
                }

            } catch(err){
                console.log(err)
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
            }
        }

        if(Object.keys(reportFormData).length){
            getReportData()
        }
        
    },[currentPage,perPage,reportFormData])



    //handleClick
    const handleClickReport = (reportType:string)=>{
        setValue('reportType',reportType)
        handleSubmit(onSubmit)()
    }

    //Handle PerPage
    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    return (
        <>
            <Card>
                <Card.Header className="bg-primary">Filter Criteria</Card.Header>
                <Card.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className='mb-1'>
                
                    <Col md={4}>
                        <DropDownField label="Report" name="report" options={reportArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose a Report" isValueKeyname={true}/>    
                    </Col>
                    {showSaleStatus && <Col md={4}>
                        <DropDownField label="Vehicle Status" name="vehicleStatus" options={vehicleStatusTypeArray}
                            control={control} placeholder="Vehicle Status" isValueKeyname={true} />
                    </Col>}
                    {showSaleStatus &&
                        <Col md={4}>
                            <DropDownField label="Sale Status" name="saleStatus" options={saleStatusTypeArray} control={control}   clearErrors={clearErrors} placeholder="Choose a status" rule={{ required: fieldRequired }}  isValueKeyname={true}/>
                        </Col>
                    }
                            {showSaleLeadStatus &&
                                <Col md={4}>
                                    <DropDownField label="Sale Lead Status" name="saleLeadStatus" options={SALE_LEADS} control={control} clearErrors={clearErrors} placeholder="Choose a status" rule={{ required: fieldRequired }} isValueKeyname={true} />
                                </Col>
                            }
                    {vehicleAgeFiler &&
                        
                        <Col md={12} className="d-flex p-3">
                        <label className="text-dark fw-semibold form-label fs-6  ">Choose Vehicle Published Age <span className="text-danger fw-bold">*</span></label> 
                        
                        <MultiRadio name="vehicleAge" control={control} label ='Vehicle Published Age'   rule={{ required: fieldRequired }}
                            options={carAgeOptions} classList="ms-2"   selectedValue={watch('vehicleAge')} />
                        </Col>
                    }

                    {showDateFiter &&
                        <>
                            <Col md={4}>
                                <div className="mb-3">
                                    <DateTimePicker label="From" name="from" type="date" control={control} max={minDate} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="From" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <DateTimePicker label="To" name="to" type="date" min={maxDate} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="To" />
                                </div>
                            </Col>
                        </>
                        
                    }
                            {showEnquiryDateFilter &&
                                <>
                                    <Col md={4}>
                                        <div className="mb-3">
                                        <DateTimePicker label="Enquiry Date" name="enquiryDate" type="date" control={control} max={maxDate} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Enquiry Date" />
                                        </div>
                                    </Col>
                                </>

                            }

                    {showSoldFilter &&
                        <>
                            <Col md={4}>
                                <DropDownField label="Year" name="year" options={years} control={control} clearErrors={clearErrors} placeholder="Choose a year" rule={{ required: fieldRequired }} isValueKeyname={true} />
                            </Col>
                            <Col md={4}>
                                <DropDownField label="Month" name="month" options={months} control={control} clearErrors={clearErrors} placeholder="Choose a month" rule={{ required: fieldRequired }} isValueKeyname={true} />
                            </Col>
                        </>
                        
                    }

                    <InputTextField name="reportType" label=""
                            placeholder="" type="hidden" clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired }} />
                    
                </Row>
                <Row>
                    <div className="d-flex " >
                        <div className="ms-auto p-2">
                            <Button className="p-2 "  type="button" onClick={()=>handleClickReport('report')} variant="success" value ="report"> Generate Report </Button> 
                        </div>
                        <div className=" p-2">
                            <Button className="p-2" type="button" onClick={()=>handleClickReport('csv')} value ="csv" variant="success"> <FontAwesomeIcon icon={faFileExcel} size='lg' /> </Button>
                        </div>
                    </div>

                </Row>
                </Form>
                </Card.Body>
            </Card>
            
            <ReportTable reportData = {reportData} 
                reportTypes = {reportTypes}
                handlePageChange = {handlePageChange}
                handlePerRowsChange = {handlePerRowsChange}
            />

            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    )
}

export default ReportCriteria