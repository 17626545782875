import { useEffect, useState } from "react";
import { Container, Col, Card, Row, Form, Button } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom"
import HTTP from "../../../config/axios";
import { useParams } from 'react-router-dom';
import { getFormatMMMddyyy, getFormatYYYY } from "../../../config/utils/dateFunctions";
import { TRANSACTION_SUCCESS } from "../../../config/utils/helper";


const CarOrder = () => {

    const { orderNo } = useParams()
    const [orderDetails, setOrderDetails] = useState<any>('')

    const checkOutPageStateValues = {
        vehicleId: orderDetails.vehicleId,
        bookingId: orderDetails.bookingId, proofUrl: orderDetails.addressProof,
        panNumber: orderDetails.panNumber, price: orderDetails.vehiclePrice
    }

    useEffect(() => {
        try {
            const getOrderDetails = async () => {

                const response = await HTTP.get(`/api/payment/order-details/${orderNo}`)
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setOrderDetails(data)
                }
            }

            getOrderDetails()
        }
        catch (error) {
            console.log("error in payment order details", error);
        }
    }, [])



    return (<><Container className="pt-3">

        <Row className="my-5">
            <div className="text-primary text-center pb-4 mt-120">
                <h2>Order Details</h2>
            </div>
            <Col md={4} className="border-end">
                <h3 className="text-primary">Personal Information</h3>
                <div>
                    <ul className="text-dark pt-3">
                        <li className="pb-4"> First Name : <span className="text-secondary">{orderDetails.firstName}</span></li>
                        <li className="pb-4"> Last Name : <span className="text-secondary">{orderDetails.lastName}</span></li>
                        <li className="pb-4"> Email : <span className="text-secondary">{orderDetails.email}</span></li>
                        <li className="pb-4"> Phone : <span className="text-secondary">{orderDetails.phone}</span></li>
                        <li className="pb-4"> Billing address : <span className="text-secondary">{orderDetails.billingAddress}</span> </li>
                        <li className="pb-4"> State : <span className="text-secondary">{orderDetails.state}</span> </li>
                        <li className="pb-4"> District : <span className="text-secondary">{orderDetails.district}</span> </li>
                        <li className="pb-4"> Pincode : <span className="text-secondary">{orderDetails.pincode}</span> </li>
                    </ul>
                </div>

            </Col>
            <Col md={4} className="border-end">
                <h3 className="text-primary">Booking Details</h3>
                <div>
                    <ul className="text-dark pt-3">
                        <li className="pb-4">  Vehicle : <span className="text-secondary">{getFormatYYYY(orderDetails.manufactureYearMonth)} {orderDetails.make} {orderDetails.model} {orderDetails.variant}</span></li>
                        <li className="pb-4">  Registration : <span className="text-secondary">{orderDetails.registration}</span></li>
                        <li className="pb-4">  Fuel : <span className="text-secondary">{orderDetails.fuel}</span></li>
                        <li className="pb-4">Variant : <span className="text-secondary">{orderDetails.variant}</span></li>
                        <li className="pb-4"> Color : <span className="text-secondary">{orderDetails.colour}</span></li>
                        <li> Proof : <Link to={orderDetails.addressProof} download={true} target="_blank" > Address Proof </Link></li>
                    </ul>
                </div>
            </Col>
            <Col md={4}>
                <h3 className="text-primary">Payment Details</h3>
                <div>
                    {
                        orderDetails?.responseData && orderDetails?.transactionStatus == TRANSACTION_SUCCESS &&
                        <ul className="text-dark pt-3">
                            <li className="pb-4"> Order ID : <span className="text-secondary">{orderDetails.orderNo}</span></li>
                            <li className="pb-4"> Paid Amount : <span className="text-secondary">{orderDetails.paymentAmount}</span></li>
                            <li className="pb-4"> Payment Date : <span className="text-secondary">{getFormatMMMddyyy(orderDetails.paymentDate)}</span></li>
                            <li className="pb-4"> Payment Method : <span className="text-secondary"> Card Payment</span> </li>
                           {orderDetails.responseData.pgMeTrnRefNo && <li className="pb-4"> Transaction Ref No : <span className="text-secondary">{orderDetails.responseData?.pgMeTrnRefNo}</span></li>}
                           {orderDetails.responseData.rrn && <li className="pb-4"> Bank Ref No : <span className="text-secondary">{orderDetails.responseData?.rrn}</span></li>} 
                        </ul>
                    }
                    {
                        orderDetails?.responseData && orderDetails?.transactionStatus != TRANSACTION_SUCCESS  &&
                        <>
                            <h3 className="text-dark">Payment transaction failed. Please try again</h3>
                            {/* <h3 className="text-dark">
                                <span className="text-primary">Reason: </span>
                                {orderDetails?.responseData.statusDesc}
                            </h3> */}
                            <Link to="/checkout" state={checkOutPageStateValues} className="btn btn-primary w-100">
                                Book&nbsp;Again
                            </Link>
                        </>

                    }
                    {
                        orderDetails.payOnline == 0 &&
                        <h3 className="text-dark">Offline payment</h3>
                    }

                </div>
            </Col>
        </Row>

    </Container>

    </>)
}


export default CarOrder;