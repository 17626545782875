import { Row, Col, Form, Container, Button, Modal } from 'react-bootstrap'
import DropDownField from '../../form/DropDownField';
import InputTextField from '../../form/InputTextField';
import DateTimePicker from '../../form/DateTimePicker';
import { useForm } from 'react-hook-form';
import { fieldRequired, min, validateOnlySpaces, validateRoundedNumber } from '../../../config/validations/rules';
import { useEffect, useState, useRef } from "react";
import HTTP from '../../../config/axios';
import { Image } from 'react-bootstrap';
import FileUpload from '../../form/FileUpload';
import { BOOKING_CANCEL_STATUS, BOOKING_COMPLETED_STATUS, VehicleSaleStatus, insuranceType } from '../../../config/utils/helper';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import InputTextAreaField from '../../form/InputTextAreaField';
import MultiDropdown from '../../form/MultiDropdown';
import { getFormatyyyymmdd } from '../../../config/utils/dateFunctions';
import { isBefore, isEqual, startOfDay } from 'date-fns';
import CustomModal from '../../common/CustomModal';
import DualInputField from '../../form/IDualInputField';

interface IMake {
    id: number;
    name: string;
    keyName: string;
    bodyTypeId: number
}
interface VehicleSpecifications {
    fuelType?: number;
    transmissionId?: number;
    noOfGear?: number;
    driveTrain?: string;
    torque?: string;
    powerWeightRatio?: number;
    maxPower?: number;
    groundClearance?: number;
    engineDisplacement?: number;
    fuelTankCapacity?: number;
}

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        data: any
    };
}

interface IVehicleDetails {
    setActiveTab: Function
    setFeatureForm: Function
    // currentVehicleDetails: Record<string, string>
    currentVehicleDetails: any
    setVehicleId: Function
    vehicleId: string | number
    setSpecificationForm: Function
    setImageForm: Function
    setDentMapForm: Function
    setVehicleDetails: Function
    vehicleUuid: string
    setvehicleUuid: Function
    setToast: Function
    setIsLoading: Function,
    initialDod: any
    setInspectionForm: Function
    setuserInfoForm: Function
}

const BasicDetailsForm: React.FC<IVehicleDetails> = ({ currentVehicleDetails,
    setVehicleDetails,
    setActiveTab,
    setFeatureForm,
    setVehicleId,
    vehicleId,
    setSpecificationForm,
    setImageForm,
    setDentMapForm,
    vehicleUuid,
    setvehicleUuid,
    setToast,
    setIsLoading,
    initialDod,
    setInspectionForm,
    setuserInfoForm }) => {


    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, resetField, watch, setValue, getValues } = useForm();
    const [makeArray, setMakeArray] = useState<IMake[]>([]);
    const [modelArray, setModelArray] = useState<IMake[]>([]);
    const [variantArray, setVariantArray] = useState<IMake[]>([]);
    const [fuelType, setFuelType] = useState<IMake[]>([]);
    const [locationArray, setLocationArray] = useState<IMake[]>([]);
    const [transmissionType, setTransmissionType] = useState<IMake[]>([]);
    const [colours, setColours] = useState([]);
    const [bodyTypes, setBodyTypes] = useState([]);
    const [lifestyles, setLifestyle] = useState([]);
    const make = watch('make')
    const [imageURL, setImageURL] = useState('')
    const [editImage, setEditImage] = useState(false)
    const [originalImage, setOriginalImage] = useState<string | Blob>('')
    const [thumbImage, setthumbImage] = useState<string | Blob>('')
    const uploadedImage = watch('uploadedImage')
    const [basicDetailUpdated, setbasicDetailUpdated] = useState(false)
    const cropperRef = useRef<ReactCropperElement>(null);
    const [savedLifestyle, setSavedLifestyle] = useState([]);
    const [outletData, setOutletData] = useState([])
    const hasPageBeenRendered = useRef(false) // ref used to verify if page has rendered completely initially
    const [selectedLifeStyle, setSelectedLifeStyle] = useState<{ value: number, label: string }[]>([])
    const selectedDealDate = initialDod ? startOfDay(new Date(initialDod)) : null
    const [initialDealDate, setInitialDealDate] = useState(selectedDealDate)
    const [isVehicleBooked, setIsVehicleBooked] = useState(false);
    const [bookingId, setBookingId] = useState([])
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const [openSoldModal, setOpenSoldModal] = useState(false);
    const [markedAsSold, setMarkedAsSold] = useState(false)
    const [specificationArray, setSpecificationArray] = useState([])
    const [basicVehicleSpecifications, setBasicVehicleSpecifications] = useState<any>({});
    const [isDealDayOffer,setIsDealDayOffer] = useState(false)
    const selectedDealDateOffer = watch('dealLastDate')
    const selectedDealDateOfferPrice = watch('dealDayOffer')
    const [openReasonModal, setOpenReasonModal] = useState(false);

    const currentDate = startOfDay(new Date());

    // Get the individual components of the date and time
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    // Format the date and time without seconds
    const minDate = `${year}-${month}-${day}`


    //Sales Status Array
    const saleStatusArray = [
        {
            id: VehicleSaleStatus.VEHICLE_UNPUBLISH_STATUS,
            name: "Unpublish"
        },
        {
            id: VehicleSaleStatus.VEHICLE_SELL_STATUS,
            name: "Sell"
        },
        {
            id: VehicleSaleStatus.VEHICLE_PRE_BOOKED_STATUS,
            name: "Pre-Booked"
        },
        {
            id: VehicleSaleStatus.VEHICLE_BOOKED_STATUS,
            name: "Booked"
        },
        {
            id: VehicleSaleStatus.VEHICLE_SOLD_STATUS,
            name: "Sold"
        },
        {
            id: VehicleSaleStatus.VEHICLE_BUY_STATUS,
            name: "Purchase"
        },
    ]

    const selectionArray = [
        {
            id: 1,
            name: "Yes"
        }, {
            id: 0,
            name: "No"
        }
    ]

    const cropImage = async () => {
        const cropper: any = cropperRef.current?.cropper;
        if (cropper) {
            const originalImage = urlToBlob(cropper.getCroppedCanvas({ width: 1180, height: 750 })?.toDataURL())
            const thumbnailImage = urlToBlob(cropper.getCroppedCanvas({ width: 400, height: 250 })?.toDataURL())
            setOriginalImage(originalImage)
            setthumbImage(thumbnailImage)
        }


    };


    const urlToBlob = (dataURL: string) => {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeType = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type: mimeType });
    }

    useEffect(() => {
        const selectedDealDate = initialDod ? startOfDay(new Date(initialDod)) : null
        setInitialDealDate(selectedDealDate)
    }, [initialDod])

    useEffect(() => {
        reset(currentVehicleDetails);
        setImageURL(vehicleId && currentVehicleDetails.imageUrl ? `${process.env.REACT_APP_CLOUDFRONT_URL}${currentVehicleDetails.imageUrl}` : '')
    }, [currentVehicleDetails]);

    useEffect(() => {
        if (vehicleUuid && currentVehicleDetails.id && makeArray.length && modelArray.length && variantArray.length &&
            locationArray.length && outletData.length && transmissionType.length &&
            bodyTypes.length && lifestyles.length && fuelType.length && colours.length) {
            hasPageBeenRendered.current = true
        } else {
            hasPageBeenRendered.current = false
        }
    }, [currentVehicleDetails, makeArray, modelArray, variantArray,
        locationArray, outletData, transmissionType, bodyTypes, lifestyles,
        fuelType, colours])

    useEffect(() => {

        const getCarDetails = async () => {
            try {
                const queryParams = { vehicleId: vehicleUuid };
                const response = await HTTP.get(`/api/admin/vehicle/vehicleDetails`, { params: queryParams });
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    data.vehicleDetails.lifestyleIds = data.lifestyle
                    data.vehicleDetails.dealLastDate = getFormatyyyymmdd(data.vehicleDetails.dealLastDate)
                    data.vehicleDetails.insuranceValidity = getFormatyyyymmdd(data.vehicleDetails.insuranceValidity)
                    const selectedDealDate = currentVehicleDetails?.dealLastDate ?
                        new Date(currentVehicleDetails?.dealLastDate) : null
                    setInitialDealDate(selectedDealDate ? startOfDay(selectedDealDate) : null)
                    setVehicleDetails(data.vehicleDetails)
                    setVehicleId(data.vehicleDetails.id)
                    setFeatureForm(true)
                    setSpecificationForm(true)
                    setFeatureForm(true)
                    setImageForm(true)
                    setDentMapForm(true)
                    setInspectionForm(true)
                    setActiveTab('feature')
                } else {
                    setToast({ open: true, message: 'Something went wrong', header: 'Error', variant: 'danger' })
                }
            }
            catch (error) {
                console.log('Error in car details API ')
            }

        }
        if (basicDetailUpdated) {
            getCarDetails()
        }

    }, [basicDetailUpdated, markedAsSold]);

    useEffect(() => {
        const handleBooking = async () => {
            const queryParams = { vehicleId: vehicleUuid };
            const response = await HTTP.get(`/api/booking/fetch-one`, { params: queryParams });
            const { data: { status, data, msg, error } } = response
            if (status == 1 && data.length) {
                setBookingId(data[0].id)
                setIsVehicleBooked(true)
            }
            else {
                setIsVehicleBooked(false)
            }
        }
        handleBooking()
    }, [vehicleUuid])

    useEffect(() => {
        if (uploadedImage) {
            setImageURL(URL.createObjectURL(uploadedImage) || '')
            setEditImage(true)
        }
        else {
            setEditImage(false)
            setImageURL(vehicleId ? `${process.env.REACT_APP_CLOUDFRONT_URL}${currentVehicleDetails.imageUrl}` : '')
        }
    }, [uploadedImage])

    useEffect(() => {
        const getCarMakeData = async () => {
            try {
                const params: any = {};
                if (currentVehicleDetails?.make) {
                    params.makeId = currentVehicleDetails?.makeId
                }
                const response = await HTTP.get(`/api/make/all`, { params });
                const { data: { status, data, msg, error } } = response
                setMakeArray(status ? data : [])
            }
            catch (error) {
                console.log('Error in Make Data API', error)
            }

        }

        const getTransmissionType = async () => {
            try {
                const params: any = {};
                if (currentVehicleDetails?.transmission) {
                    params.transmissionId = currentVehicleDetails?.transmission
                }
                const response = await HTTP.get(`/api/admin/transmission/fetch-all-transmission`, { params });
                const { data: { status, data, msg, error } } = response
                setTransmissionType(status ? data : [])
            }
            catch (error) {
                console.log('Error in transmission API', error)
            }

        }

        const getAllBodyTypes = async () => {
            try {
                const params: any = {};
                if (currentVehicleDetails?.bodyTypeId) {
                    params.bodyTypeId = currentVehicleDetails?.bodyTypeId
                }
                const response = await HTTP.get(`/api/admin/vehicle/body-types/fetch-all-types`, { params });
                const { data: { status, data, msg, error } } = response
                setBodyTypes(status ? data : [])
            }
            catch (error) {
                console.log('Error in Body Types API', error)
            }
        }

        const getAllLifestyle = async () => {
            const params: any = {};
            if (currentVehicleDetails?.lifestyle) {
                params.lifestyleId = currentVehicleDetails?.lifestyle
            }
            try {
                const response = await HTTP.get(`/api/admin/vehicle/lifestyle/fetch-all-style`, { params });
                const { data: { status, data, msg, error } } = response
                setLifestyle(data.map((style: any) => {
                    return { value: style.id, label: style.name }
                }))
            }
            catch (error) {
                console.log('Error in Lifestyle API', error)
            }
        }

        const getFuelTypeData = async () => {
            const params: any = {};
            if (currentVehicleDetails?.fuel) {
                params.fuelId = currentVehicleDetails?.fuel
            }
            try {
                const response = await HTTP.get(`/api/admin/fuel/fetch-all-fuel`, { params });
                const { data: { status, data, msg, error } } = response
                setFuelType(status ? data : [])
            }
            catch (error) {
                console.log("Error in Fuel API ")
            }
        }

        const getLocation = async () => {
            try {
                const params: any = { status: 1 };
                if (currentVehicleDetails?.location) {
                    params.locationId = currentVehicleDetails?.location
                }
                const locationData = await HTTP.get('/api/location/fetch-all', { params });
                const { data: { status, data, msg, error } } = locationData
                setLocationArray(status ? data : [])
            }
            catch (error) {
                console.log("Error in Location API")
            }
        }



        const getColoursData = async () => {
            try {
                const params: any = { status: 1, type: 2 };
                if (currentVehicleDetails?.color) {
                    params.colorId = currentVehicleDetails?.color
                }
                const response = await HTTP.get(`/api/admin/vehicle/colours/all`, { params });
                const { data: { status, data, msg, error } } = response
                setColours(status ? data : [])
            }
            catch (error) {
                console.log('Error in Color API')
            }

        }

        getAllLifestyle()
        getAllBodyTypes()
        // getColoursData()
        getLocation()
        getFuelTypeData()
        getTransmissionType()
        getCarMakeData()
    }, []);

    useEffect(() => {
        const getAllOutlets = async () => {
            const params = { status: 1, locationId: watch('location') };
            const response = await HTTP.get(`/api/admin/outlet/fetch-all`, { params });
            const { data: { status, data, msg, error, count } } = response;

            let hasSelectedOutlet = false
            data.rows.forEach((outlet: any) => {
                if (outlet.id == getValues('outletId')) hasSelectedOutlet = true
            })
            if (!hasSelectedOutlet) setValue('outletId', '')

            setOutletData(data.rows);
        }

        getAllOutlets()
    }, [watch('location')])

    useEffect(() => {
        // To set lifestyle value from DB 
        const setLifestyleFrmDb = () => {

            const styleData = lifestyles.filter((style: any) => {
                return currentVehicleDetails?.lifestyleIds?.includes(style.value)
            })

            setValue('lifestyle', styleData)
        }
        currentVehicleDetails.id && lifestyles.length > 0 && setLifestyleFrmDb()
    }, [currentVehicleDetails, lifestyles])


    useEffect(() => {
        const handleMakeChange = async () => {
            try {
                const params: any = {};
                if (currentVehicleDetails?.model) {
                    params.modelId = currentVehicleDetails?.model
                }
                const response = await HTTP.get(`/api/model/get-by-make-id/${make}`, { params });
                const { data: { status, data, msg, error } } = response;
                if (status == 1) {
                    setModelArray(data)

                    if (hasPageBeenRendered.current) {
                        resetField('model', {
                            keepDirty: false,
                            keepError: false,
                            keepTouched: false,
                            defaultValue: null
                            // defaultValue: currentVehicleDetails?.model || '' // when provided with defaultVlaue update both input value and internal defaultValues
                        })
                        resetField('variant', {
                            keepDirty: false,
                            keepError: false,
                            keepTouched: false,
                            defaultValue: null // when provided with defaultVlaue update both input value and internal defaultValues
                        })
                    }

                } else {
                    setModelArray([])
                }
            }
            catch (error) {
                console.log('Error in Make change API ')
            }


        };
        if (make) {
            handleMakeChange()
        }

    }, [make])

    const model = watch('model')
    useEffect(() => {
        const handleModelChange = async () => {
            try {
                const params: any = {};
                if (currentVehicleDetails?.variant) {
                    params.variantId = currentVehicleDetails?.variant
                }
                const response = await HTTP.get(`/api/vehicle/get-variant-by-model-id/${model}`, { params });
                const { data: { status, data, msg, error } } = response;
                if (status == 1) {
                    setVariantArray(data)
                    if (hasPageBeenRendered.current) {
                        resetField('variant', {
                            keepDirty: false,
                            keepError: false,
                            keepTouched: false,
                            defaultValue: null
                            // defaultValue: currentVehicleDetails?.variant || '' // when provided with defaultVlaue update both input value and internal defaultValues
                        })
                    }
                } else {
                    setVariantArray([])
                }
            }
            catch (error) {
                console.log("Error in model fetch api", error)
            }

        };
        if (model) {
            handleModelChange()
        }
    }, [model])

    const setBodyType = (selectedVariantId: number) => {
       
        variantArray.forEach((variant: any) => {
            if (variant.id == selectedVariantId) {
                setValue('bodyTypeId', variant.bodyTypeId);
            }
        });
        
    };
    

    useEffect(() => {

        try {
            
                const selectedVariantId = watch('variant')
                if (selectedVariantId) {
                    setBodyType(selectedVariantId)


                const getVariantSpecDetails = async () => {
                    

                    const response = await HTTP.get(`/api/admin/variant/getVariantSpecDetails/${selectedVariantId}`);
                    const { data: { status, data, msg, error } } = response

                    if (data.transformedDetails && Object.keys(data.transformedDetails).length > 0) {
                        // Map over transformedDetails to set form values
                        Object.entries(data.transformedDetails).forEach(([keyName, value]) => {
                            setValue(keyName, value);
                        });
                    }
                }

                if (hasPageBeenRendered.current || (vehicleId == '') ){
                    getVariantSpecDetails()
                }
            }
            } catch (err) {
                console.error(err);
            }
        
    }, [watch('variant')])


    useEffect(() => {
        if(colours.length && currentVehicleDetails.color){
            setValue('color', currentVehicleDetails.color)
        }
    },[ colours])

    useEffect(() => {
        const getVariantColours = async () => {
            try {
                const variantId = watch('variant') || currentVehicleDetails.variant
                const response = await HTTP.get(`/api/admin/varaint-colours/${variantId}`);
                const { data: { status, data, msg, error } } = response
                setColours(status ? data : [])
            }
            catch (error) {
                console.log('Error in Colour API', error)
            }
        }


        if (watch('variant') || currentVehicleDetails.variant ){
            getVariantColours()
        }
    },[ watch('variant'), currentVehicleDetails.variant])


    useEffect(() => {

        const addLifeStyles = () => {
            const selectedOptions: { value: number, label: string }[] = []
            watch('lifestyle').forEach((style: any) => {
                if (style?.value) selectedOptions.push(style)
            })
            setSelectedLifeStyle(selectedOptions)
        }
        watch('lifestyle') && addLifeStyles()

    }, [watch('lifestyle')])


    const dealDateValidation = () => {
        let dealDate = watch('dealLastDate') ? new Date(watch('dealLastDate')) : null
        if (dealDate) dealDate = startOfDay(dealDate)
        const dealDateCondition1inAdd = !vehicleId && dealDate &&
            watch('saleStatus') != VehicleSaleStatus.VEHICLE_SELL_STATUS
        const dealDateCondition1inEdit = vehicleId && dealDate && initialDealDate && !isEqual(dealDate, initialDealDate) &&
            watch('saleStatus') != VehicleSaleStatus.VEHICLE_SELL_STATUS
        const dealDateCondition2inEdit = vehicleId && dealDate && !initialDealDate &&
            watch('saleStatus') != VehicleSaleStatus.VEHICLE_SELL_STATUS


        if (dealDateCondition1inAdd || dealDateCondition1inEdit || dealDateCondition2inEdit) {
            setToast({
                open: true, header: 'Warning',
                message: 'Deal last date can only be selected for a vehicle with sell status',
                variant: 'danger'
            })
            return false
        }

        const dealDateCondition2inAdd = !vehicleId && dealDate && dealDate < currentDate
        const dealDateCondition3inEdit = vehicleId && dealDate && initialDealDate &&
            !isEqual(dealDate, initialDealDate) && isBefore(dealDate, currentDate)
        const dealDateCondition4inEdit = vehicleId && dealDate && !initialDealDate &&
            isBefore(dealDate, currentDate)

        if (dealDateCondition2inAdd || dealDateCondition3inEdit || dealDateCondition4inEdit) {
            setToast({
                open: true, header: 'Warning', variant: 'danger',
                message: 'Deal last date must be a future date'
            })
            return false
        }
        return true
    }

    const regDateValidation = () => {

        const registrationDate = startOfDay(new Date(watch('registration')))
        const manufactureDate = startOfDay(new Date(watch('manufacture')))

        if (watch('registration') && watch('manufacture') && isBefore(registrationDate, manufactureDate)) {
            setToast({
                open: true, header: 'Warning', variant: 'danger',
                message: 'Manufacture date must be before registration date',
            })
            return false
        }
        return true
    }

    const insuranceDateValidation = () => {

        const registrationDate = startOfDay(new Date(watch('registration')))
        const insuranceDate = startOfDay(new Date(watch('insuranceValidity')))

        if (watch('registration') && watch('insuranceValidity') && isBefore(insuranceDate, registrationDate)) {
            setToast({
                open: true, header: 'Warning', variant: 'danger',
                message: 'Registration date before Insurance Validity',
            })
            return false
        }
        return true
    }

    const dealDateVAlidationOffer = () => {
        if (selectedDealDateOffer) {
          
            if (!selectedDealDateOfferPrice){
                setToast({
                    open: true,
                    header: 'Warning',
                    variant: 'danger',
                    message: 'Provide the deal of the day offer',
                });
                return false;

            }
        }
       
        return true;
    }

    const checkValidation = () => {
        return dealDateValidation() && regDateValidation() && insuranceDateValidation() && dealDateVAlidationOffer()
    }

    const submitForm = async (formValues: any) => {
        try {
          
            const isValidationPass = checkValidation()
            if (!isValidationPass) return false

            const lifestyleIds = formValues.lifestyle ? formValues.lifestyle.map((style: any) => style.value):[]
            setIsLoading(true);
            const specList: any = []
            const removedSpecification: any = []
            
            Object.keys(basicVehicleSpecifications).map((spec: any) => {
                formValues[spec] && specList.push({ id: basicVehicleSpecifications[spec], value: formValues[spec], keyName: spec })
            })

            Object.keys(basicVehicleSpecifications).forEach((spec: any) => {
                if (!formValues[spec]) {
                    removedSpecification.push(spec);
                }
            });
            
            const formData = new FormData()
            formData.append('id', vehicleId.toString());
            formData.append('vehicleSpecification', JSON.stringify(specList));
            formData.append('removedSpecification', JSON.stringify(removedSpecification));
            formData.append('basicVehicleSpecifications', JSON.stringify(basicVehicleSpecifications));
            formData.append('makeId', formValues.make);  
            formData.append('modelId', formValues.model);
            formData.append('variantId', formValues.variant);
            formData.append('oldVariantId', currentVehicleDetails.variant);
            formValues.outletId && formData.append('outletId', formValues.outletId );  
            formValues.km && formData.append('kmDriven', formValues.km ); 
            formValues.owners  && formData.append('numberOfOwners', formValues.owners ); 
            formValues.transmission  && formData.append('transmissionId', formValues.transmission );
            formValues.fuel &&  formData.append('fuelId', formValues.fuel );
            formValues.registration && formData.append('registrationYearMonth', formValues.registration);
            formValues.manufacture && formData.append('manufactureYearMonth', formValues.manufacture );
            formData.append('colourId', formValues.color);
            formData.append('price', formValues.price);  
            formData.append('bodyTypeId', formValues.bodyTypeId);
            lifestyleIds && formData.append('lifestyle', JSON.stringify(lifestyleIds));
            formData.append('registration', formValues.registrationNumber );
            formData.append('vehicleImage', originalImage); 
            formData.append('vehicleThumbnailImage', thumbImage );
            formData.append('currentImage', currentVehicleDetails.imageUrl);
            formData.append('currentThumbnailImage', currentVehicleDetails.thumbnailUrl);
            formData.append('imageId', currentVehicleDetails.imageId);
            formData.append('uuid', currentVehicleDetails.uuid );
            formData.append('saleStatus', formValues.saleStatus);
            formValues.assured && formData.append('assured', formValues.assured );  
            formValues.testDrive && formData.append('testDrive', formValues.testDrive );
            formValues.dealLastDate && formData.append('dealLastDate', formValues.dealLastDate );
            formValues.dealDayOffer && formData.append('dealDayOffer', formValues.dealDayOffer );
            formData.append('removedLifestyle', JSON.stringify(checkSavedLifestyle(lifestyleIds))); 
            formValues.recommended && formData.append('recommended', formValues.recommended );  
            formValues.insuranceType && formData.append('insuranceType', formValues.insuranceType);
            formValues.insuranceValidity &&  formData.append('insuranceValidity', formValues.insuranceValidity );
            formValues.rto && formData.append('rto', formValues.rto );
            formValues.fuel && formData.append('fuel', formValues.fuel );
            formData.append('unpublishedReason', formValues.unpublishedReason);

            const { data: { data, status, message } }: responseType = await HTTP({
                method: 'post',
                url: `/api/admin/vehicle/${vehicleId ? 'editVehicle' : 'addVehicle'}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })

            if (status == 1) {
                setbasicDetailUpdated(true)

                if (!vehicleId) { // Set vehcile Id in Add vehcile response
                    setVehicleId(data.id)
                    setvehicleUuid(data.uuid)
                }
            }
        }
        catch (error) {
            console.log("Something went wrong", error)
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        } finally {
            setIsLoading(false);
        }

    }

    const checkSavedLifestyle = (lifestyle: any) => {
        const currentLifestyle = currentVehicleDetails.lifestyleIds;
        if (currentLifestyle) {
            const notInLifestyle = currentLifestyle.filter((item: any) => !lifestyle.includes(item));
            return notInLifestyle

        } else {
            return [];
        }
    }

    useEffect(() => {
      
        watch('dealLastDate') && dealDateValidation()

    }, [watch('dealLastDate'), initialDealDate])

    useEffect(() => {

        regDateValidation()

    }, [watch('manufacture'), watch('registration')])

    useEffect(() => {

        insuranceDateValidation()

    }, [watch('registration'), watch('insuranceValidity')])


    const cancelBooking = async () => {
        const updateData = { id: bookingId, status: BOOKING_CANCEL_STATUS };
        const response = await HTTP.post(`/api/booking/update-status`, updateData);
        const { data: { status, data, message, error } } = response
        if (status == 1) {
            setBookingId([])
            setIsVehicleBooked(false)
            setOpenActivateModal(false);
            setToast({ open: true, message: message, header: 'Success', variant: 'success' })
        }
        else {
            setOpenActivateModal(false);
            setToast({ open: true, message: message, header: 'Error', variant: 'danger' })
        }
    }

    const markAsSold = async () => {
        const updateData = { bookingId: bookingId, bookStatus: BOOKING_COMPLETED_STATUS, vehicleId: vehicleId };
        const response = await HTTP.post(`/api/admin/vehicle/mark-as-sold`, updateData);
        const { data: { status, data, message, error } } = response
        if (status == 1) {
            setBookingId([])
            setIsVehicleBooked(false)
            setOpenSoldModal(false);
            setValue('saleStatus', VehicleSaleStatus.VEHICLE_SOLD_STATUS)
            setVehicleDetails((prev: any) => {
                prev.saleStatus = VehicleSaleStatus.VEHICLE_SOLD_STATUS
                return prev
            })

            setToast({ open: true, message: message, header: 'Success', variant: 'success' })
        }
        else {
            setOpenSoldModal(false);
            setToast({ open: true, message: message, header: 'Error', variant: 'danger' })
        }
    }

    const handleCancelClick = () => {
        setOpenSoldModal(false);
        setOpenActivateModal(false)
    }
    const bookingCancelClick = () => {
        setOpenActivateModal(true);
    }

    const markAsSoldClick = () => {
        setOpenSoldModal(true);
    }

    useEffect(() => {
        if (setSpecificationArray.length){
            const getVehicleSpecificationDetails = async () => {
                try {
                    const params = { vehicleId: vehicleId, basic: 1 }
                    const response = await HTTP.get(`/api/admin/vehicle/specificationDetails`, { params });
                    const { data: { status, data, msg, error } } = response
                    if (status == 1 && data.length) {
                        const currentlySelectedOption: any = {}
                        data.map((specification: any) => {
                            setValue(specification.keyName, specification.value)
                            currentlySelectedOption[specification.keyName] = specification.value
                        })
                        //setSpecificationDetails(currentlySelectedOption)
                    }
                }
                catch (error) {

                }

            }
            if (vehicleId) {
                getVehicleSpecificationDetails()
            }

        }
       
    }, [setSpecificationArray, vehicleId])

    useEffect(() => {
        const getSpecificationData = async () => {
            const params = { basicData: true }
            const response = await HTTP.get(`/api/admin/specification/all`, { params });
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setSpecificationArray(data);
                const basicVehicleSpecs = data.reduce((acc: { [key: string]: number }, item: { keyName: string, id: number }) => {
                    acc[item.keyName] = item.id;
                    return acc;
                }, {});
                setBasicVehicleSpecifications(basicVehicleSpecs);
            }
        }
        getSpecificationData()

    }, []);

    const renderInputField = (data: any) => {
        switch (data.fieldType) {
            case "number":
                return (
                    <InputTextField
                        label={data.name}
                        name={data.keyName}
                        type="number"
                        control={control}
                        min={0}
                        clearErrors={clearErrors}
                        placeholder={`Enter ${data.name}`}
                        unit={data.unit}
                    />
                );

            case "text":
                return (
                    <InputTextField
                        label={data.name}
                        name={data.keyName}
                        type="text"
                        control={control}
                        clearErrors={clearErrors}
                        placeholder={`Enter ${data.name}`}
                        unit={data.unit}
                        rule={{ validate: validateOnlySpaces }}
                    />
                );

            case "decimal":
                return (
                    <InputTextField
                        label={data.name}
                        name={data.keyName}
                        type="number"
                        control={control}
                        step="any"
                        min={0}
                        placeholder={`Enter ${data.name}`}
                        unit={data.unit}
                    />
                );

            case "dropdown":
                const options = JSON.parse(data.options || "[]");
                return (
                    <DropDownField
                        label={data.name}
                        name={data.keyName}
                        options={options}
                        control={control}
                        placeholder={`Select ${data.name}`}
                    />
                );

            default:
                return (
                    <InputTextField
                        label={data.name}
                        name={data.keyName}
                        type="number"
                        control={control}
                        min={0}
                        clearErrors={clearErrors}
                        placeholder={`Enter ${data.name}`}
                        unit={data.unit}
                    />
                );
        }
    };


    const onModalSubmit = async (modalData: any) => {
        setOpenReasonModal(false);

        const formValues = { ...getValues(), unpublishedReason: modalData.unpublishedReason };

        await submitForm(formValues);
    };


    const onSubmit = async (formValues: any) => {


        if (formValues.saleStatus == VehicleSaleStatus.VEHICLE_UNPUBLISH_STATUS) {
            setOpenReasonModal(true);
        }
        else {
            await submitForm(formValues);
        }
    }



    return (<>
        <Container fluid>
            <h1> Vehicle Details </h1>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Make" name="make" options={makeArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose a make" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Model" name="model" options={modelArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose a model" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Variant" name="variant" options={variantArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose a variant" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Transmission" name="transmission" options={transmissionType} control={control}  clearErrors={clearErrors} placeholder="Choose a transmission" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Fuel Type" name="fuel" options={fuelType} control={control} clearErrors={clearErrors} placeholder="Choose a fuel" />
                        </div>
                    </Col>

                    <Col md={4} >
                        <DualInputField step="any"  type='number' name1="max-power-(bhp)" name2="max-power-(rpm)" label="Max Power"
                            rule={{ required: fieldRequired }}
                            control={control} clearErrors={clearErrors} unit1="bhp" unit2="rpm" />
                    </Col>
                    <Col md={4} >   
                        <DualInputField step="any" type='number' name1="max-torque-(nm)" name2="max-torque-(rpm)" label="Torque" control={control}
                            rule={{ required: fieldRequired }}
                        clearErrors={clearErrors} unit1="nm" unit2="rpm"/>
                    </Col>

                    {specificationArray
                        .filter((data: any) => data.basic == 1) // Filter out items where basic is not 1
                        .map((data: any, index: number) => (
                            <>
                                {!(data.keyName.includes('max-power') || data.keyName.includes('torque') )&& 
                                    <Col className='' md={4} key={data.id}>
                                        {renderInputField(data)}
                                    </Col> 
                                }
                            </>
                        ))
                    }

                    <Col md={4}>
                        <div className="mb-3">
                            <InputTextField label="Registration Number" name="registrationNumber" type="text" control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Enter registration number" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <InputTextField label="Price" name="price" type="number" control={control} rule={{ required: fieldRequired, min: min(1), validate: validateRoundedNumber }} clearErrors={clearErrors} placeholder="Price" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <InputTextField label="KM's" name="km" type="number" control={control} rule={{min: min(1) }} clearErrors={clearErrors} placeholder="Enter no of KM's" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <InputTextField label="No of owners" name="owners" type="number" control={control} rule={{ min: min(1), validate: validateRoundedNumber }} clearErrors={clearErrors} placeholder="Enter no of owners" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="District" name="location" options={locationArray} control={control} rule={{}} clearErrors={clearErrors} placeholder="Choose a district" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Outlet" name="outletId" options={outletData} control={control}  clearErrors={clearErrors} placeholder="Choose a outlet" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DateTimePicker label="Manufacture Date" name="manufacture" type="date" control={control} max={minDate}  clearErrors={clearErrors} placeholder="Manufacture date" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DateTimePicker label="Registration Date" name="registration" type="date" max={minDate} control={control} clearErrors={clearErrors} placeholder="Registration date" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Insurance Type" name="insuranceType" options={insuranceType} control={control}  clearErrors={clearErrors} placeholder="Insurance Type" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DateTimePicker label="Insurance Validity" name="insuranceValidity" type="date" control={control} clearErrors={clearErrors} placeholder="Insurance Validity" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <InputTextField label="RTO" name="rto" type="text" control={control} clearErrors={clearErrors} placeholder="RTO" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Colour" name="color" options={colours} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose a colour" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Body Types" name="bodyTypeId" options={bodyTypes} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose a body type" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <MultiDropdown label="Lifestyle" name="lifestyle" options={lifestyles}
                                selectedValues={watch('lifestyle')} setValues={setSelectedLifeStyle}
                                control={control} 
                                clearErrors={clearErrors} placeholder="Choose lifestyles" />
                        </div>
                    </Col>
                    <Col md={4}>
                        {!isVehicleBooked ? (
                            <div className="mb-3">
                                <DropDownField label="Sale Status" name="saleStatus" options={saleStatusArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose a status" />
                            </div>
                        ) : (
                            <div className="mb-3">
                                <DropDownField label="Sale Status" name="saleStatus" options={saleStatusArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose a status" isDisabled={true} />
                                <Form.Text className="text-danger">Vehicle is already booked, cancel the booking or mark as sold</Form.Text>
                            </div>
                        )}

                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Assured Status" name="assured" options={selectionArray} control={control}  clearErrors={clearErrors} placeholder="Choose an option" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Test Drive Availability" name="testDrive" options={selectionArray} control={control}  clearErrors={clearErrors} placeholder="Choose an option" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DateTimePicker label="Deal last date" name="dealLastDate" type="date" control={control} clearErrors={clearErrors} />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                         <InputTextField label="Deal day offer" name="dealDayOffer" type="number" rule={{ min: min(1) }} control={control} clearErrors={clearErrors} />   
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Recommended" name="recommended" options={selectionArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose an option" />
                        </div>
                    </Col>
                    <Col md={4}>

                        <div className='mb-1'>
                            <FileUpload name="uploadedImage" label="Vehicle Image" accept=".jpeg,.jpg,.png"
                                control={control} rule={{ required: vehicleId ? false : fieldRequired }} />
                            <p className="text-secondary fs-7"><FontAwesomeIcon icon={faExclamationCircle} /> Upload car images in png/jpg/jpeg format with 1150 * 750 pixel and less than 2 MB size </p>
                            {/* <Form.Text className="text-black-50">Only Jpeg, png and jpg formats accepted</Form.Text> */}
                        </div>
                    </Col>
                </Row>
                <Row>

                    <div>
                        {imageURL && !editImage && <Image src={imageURL} width={"30%"} />}
                    </div>

                    {editImage && <Cropper
                        ref={cropperRef}
                        crop={cropImage}
                        src={imageURL}// Replace with your image URL
                        style={{ width: 460, height: 300 }}
                        aspectRatio={1180 / 750}
                        // minCropBoxHeight={750}
                        // minCropBoxWidth={1180} // Set the desired aspect ratio
                        guides={true} // Show cropping guidelines
                    />}

                </Row>
                <Button type="submit" className='mt-3'>Save & Continue</Button>
                {isVehicleBooked && <><Button className="ms-3 mt-3" onClick={bookingCancelClick}>Cancel Booking</Button> <Button className="ms-3 mt-3" onClick={markAsSoldClick}>Mark as sold</Button> </>}
            </Form>
        </Container>
        <CustomModal isOpen={openActivateModal} title="Cancel Booking"
            component={<p>Are you sure you want to continue?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={cancelBooking}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openSoldModal} title="Mark as sold"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenSoldModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={markAsSold}>
                    Confirm
                </Button></>} />

        <Modal show={openReasonModal} onHide={() => setOpenReasonModal(false)}>
            <Form onSubmit={handleSubmit(onModalSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reason for Unpublishing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputTextAreaField label="Reason" name="unpublishedReason" control={control} rule={{ required: fieldRequired }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenReasonModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>

    </>)
}
export default BasicDetailsForm