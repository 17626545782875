import React from 'react'
import { Navbar, Container, Nav, NavDropdown, Row, Col, Image, Button, Tooltip } from "react-bootstrap";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faLocationDot,
  faUserCircle,
  faSearch,
  faBars,
  faCar,
  faHistory,
  faCircleQuestion,
  faGift,
  faCircleDollarToSlot,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import { encryptLocalStorage } from '../../config/utils/storage';


interface MobileHeaderProps {
  isLoggedIn: boolean;
  openLocationModal: () => void;
  directToCompare: () => void;
  handleLogout: () => void;
  openSignInPage: () => void;
  showSearchModal: boolean;
  toggleSearch: () => void;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
  isLoggedIn,
  openLocationModal,
  directToCompare,
  handleLogout,
  openSignInPage,
  showSearchModal,
  toggleSearch,
}) => {
  
  const currentLocation = useLocation();
  const navigate = useNavigate();
  
  const handleSigninDirect  = () => {
    encryptLocalStorage.setItem("previousLoggedoutLocation","/my-favourites")
    navigate("/sign-in")
}
  return (

   <Container id="nav-links">
              <Row className="w-100 ">
                <Col md={10} sm={10} className="w-84 ">
                  <NavLink to="/">
                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/pvsLogo.svg`} width={"95%"} alt="logo" />
                  </NavLink>
                </Col>
                <Col md={2} sm={2} className="w-8 ps-1">
                  {/* {showSearchModal && <SearchBox searchIcon={true} />} */}
                  {!showSearchModal && <FontAwesomeIcon icon={faSearch} onClick={toggleSearch} className="nav-link mt-1 " style={{ cursor: 'pointer' }}></FontAwesomeIcon>}
                </Col>
                <Col md={2} sm={2} className="w-8 ">
                  {!isLoggedIn ? (
                    <FontAwesomeIcon className="nav-link mt-1" icon={faBars} onClick={openSignInPage} style={{ cursor: 'pointer' }} title="Locations"></FontAwesomeIcon>

                  ) : (
                    <NavDropdown rootCloseEvent='mousedown'
                      title={<FontAwesomeIcon icon={faBars}></FontAwesomeIcon>}
                      id="collasible-nav-dropdown"
                      align="end">
                      <NavDropdown.Item as={Link} to="/profile/?tab=sideTab_1" className="py-2">
                        <FontAwesomeIcon icon={faCar} className="pe-3 text-primary"></FontAwesomeIcon>
                        Test Drive
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/profile/?tab=sideTab_2" className="py-2">
                        <FontAwesomeIcon icon={faCar} className="pe-3 text-primary"></FontAwesomeIcon>
                        Booking
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/blogs" className="py-2">
                        <FontAwesomeIcon icon={faHistory} className="pe-3 text-primary"></FontAwesomeIcon>
                        Blogs
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/testimonials" className="py-2">
                        <FontAwesomeIcon icon={faCircleQuestion} className="pe-3 text-primary"></FontAwesomeIcon>
                        Customer reviews
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.popularmaruti.com/careers" target="_blank" className="py-2">
                        <FontAwesomeIcon icon={faCircleDollarToSlot} className="pe-3 text-primary"></FontAwesomeIcon>
                        Careers
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/refer-and-earn" className="py-2">
                        <FontAwesomeIcon icon={faGift} className="pe-3 text-primary"></FontAwesomeIcon>
                        Refer & earn
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/contact-us" className="py-2">
                        <FontAwesomeIcon icon={faCircleUser} className="pe-3 text-primary"></FontAwesomeIcon>
                        Contact us
                      </NavDropdown.Item>
                    </NavDropdown>)}
                </Col>
              </Row>
              <Row className="w-100  mt-2 ">
                <Col sm={3} xs={3} className="  ">

                  <NavLink
                    className="nav-link fw-bold"
                    to={`/buy-used-car`}
                  >
                    Buy car
                  </NavLink>
                </Col>
                <Col sm={3} xs={3} className=" ps-0 pe-0">
                  <NavLink className="nav-link fw-bold" to="/sell-your-car">
                    Sell car
                  </NavLink>
                </Col>
                <Col sm={2} xs={2} className=" px-0 ">
                  <NavLink className="nav-link fw-bold" to="/finance">
                    Finance
                  </NavLink>
                </Col>
                <Col sm={1} xs={1} className=" ">
                  <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/compare.svg`}
                    onClick={() => directToCompare()} width={"25px"} className="text-end link-pointer" />
                </Col>
                <Col sm={1} xs={1} className=" ">
                  {isLoggedIn ? <Link
                    className="nav-link "
                    to={"/my-favourites"}
                  >
                    <FontAwesomeIcon
                      icon={faHeart}
                      className="text-primary"
                      title="Favourites"
                    ></FontAwesomeIcon>
                  </Link> : <div className="nav-link ">
                    <FontAwesomeIcon
                      icon={faHeart}
                      className="text-primary"
                      title="Favourites"
                      onClick={handleSigninDirect}
                    ></FontAwesomeIcon> </div>}
                </Col>
                <Col sm={1} xs={1} className=" ">
                  <FontAwesomeIcon
                    className="nav-link mt-1"
                    icon={faLocationDot}
                    onClick={openLocationModal}
                    style={{ cursor: "pointer" }}
                    title="Locations"
                  ></FontAwesomeIcon>
                </Col>
                <Col sm={1} xs={1} className=" ">
                  {isLoggedIn ? (
            <NavDropdown rootCloseEvent='mousedown'
                      title={
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          title="Profile"
                        ></FontAwesomeIcon>
                      }
                      id="collasible-nav-dropdown "
                    >
                      <NavDropdown.Item as={Link} className="px-3 " to="/profile">
                        {" "}
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <Nav.Link
                        className="px-3"
                        as={NavLink}
                        to="#"
                        onClick={handleLogout}
                      >
                        {" "}
                        Logout
                      </Nav.Link>
                    </NavDropdown>) : (
                    <Link className="nav-link " to="/sign-in">
                      <FontAwesomeIcon icon={faUserCircle} title="Sign in"></FontAwesomeIcon>
                    </Link>

                  )
                  }
                </Col>
              </Row>
            </Container>
          
  )
}

export default MobileHeader