import React from 'react'
import { Navbar, Container, Nav, NavDropdown, Row, Col, Image, Button, Tooltip } from "react-bootstrap";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faLocationDot,
  faUserCircle,
  faSearch,
  faBars,
  faCar,
  faHistory,
  faCircleQuestion,
  faGift,
  faCircleDollarToSlot,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import SearchBox from '../../components/common/SearchBox';
import { encryptLocalStorage } from '../../config/utils/storage';

interface DesktopHeaderProps {
  isLoggedIn: boolean;
  openLocationModal: () => void;
  directToCompare: () => void;
  handleLogout: () => void;

  openSignInPage: () => void;
  isHeaderVisible: boolean;
  isTablet: boolean;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  isLoggedIn,
  openLocationModal,
  directToCompare,
  handleLogout,

  openSignInPage,
  isHeaderVisible,
  isTablet,
}) => {

  const currentLocation = useLocation();
  const navigate = useNavigate();
  
  const handleSigninDirect  = () => {
    encryptLocalStorage.setItem("previousLoggedoutLocation","/my-favourites")
    navigate("/sign-in")
}
  return (

    <Container  id="nav-links" className="mt-3">
        
              <NavLink to="/" className="mob-padd" >
                <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/pvsLogo.svg`} width="100%" alt="logo"></img>
              </NavLink>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />

              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto">
                  <NavLink className="nav-link fw-bold" to={`/buy-used-car`}>
                    Buy&nbsp;car
                  </NavLink >
                  <NavLink className="nav-link fw-bold" to="/sell-your-car">
                    Sell&nbsp;car
                  </NavLink>
                  <NavLink className="nav-link fw-bold" to="/finance">
                    Finance
                  </NavLink>

                  {isLoggedIn ? <Link className="nav-link " to={ "/my-favourites" } >
                    <FontAwesomeIcon icon={faHeart} className="text-primary" title="Favourites"></FontAwesomeIcon>
                  </Link> : <div className="nav-link "> <FontAwesomeIcon icon={faHeart} className="text-primary" title="Favourites" onClick={handleSigninDirect} style={{"cursor":"pointer"}}></FontAwesomeIcon></div> }
                  <div className="nav-link link-pointer">
                    <FontAwesomeIcon className=" mt-1" icon={faLocationDot} onClick={openLocationModal} title="Locations"></FontAwesomeIcon>
                  </div>

                  <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/compare.svg`} title="Compare"
                    onClick={() => directToCompare()} width={"25px"} className="text-end link-pointer" />

                  {
                    isLoggedIn ? (
                      <NavDropdown className="z-3 "
                      rootCloseEvent='mousedown'
                        title={
                          <FontAwesomeIcon icon={faUserCircle} title="Profile"></FontAwesomeIcon>
                        }
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item as={Link} className="nav-link" to="/profile">
                          {" "}
                          Profile
                        </NavDropdown.Item >
                        <NavDropdown.Divider />
                        <Nav.Link as={NavLink} to="#" onClick={handleLogout}>
                          {" "}
                          Logout
                        </Nav.Link>
                      </NavDropdown>
                    ) : (
                      <Link className="nav-link " to="/sign-in">
                        <FontAwesomeIcon icon={faUserCircle} title="Sign in"></FontAwesomeIcon>
                      </Link>
                    )
                  }
                  <SearchBox />
                  {/* <FontAwesomeIcon icon={faSearch} onClick={toggleSearch} className="nav-link mt-1" style={{ cursor: 'pointer' }}></FontAwesomeIcon> */}
                  {
                    !isLoggedIn ? (
                      <FontAwesomeIcon className="nav-link mt-1" icon={faBars} onClick={openSignInPage} style={{ cursor: 'pointer' }} title="Locations"></FontAwesomeIcon>

                    ) : (
                      
                <NavDropdown className="z-3" rootCloseEvent='mousedown'
                        title={<FontAwesomeIcon icon={faBars}></FontAwesomeIcon>}
                        id="collasible-nav-dropdown"
                        align="end">
                        <NavDropdown.Item as={Link} to="/profile/?tab=sideTab_1" className="py-2">
                          <FontAwesomeIcon icon={faCar} className="pe-3 text-primary"></FontAwesomeIcon>
                          Test Drive
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/profile/?tab=sideTab_2" className="py-2">
                          <FontAwesomeIcon icon={faCar} className="pe-3 text-primary"></FontAwesomeIcon>
                          Booking
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/blogs" className="py-2">
                          <FontAwesomeIcon icon={faHistory} className="pe-3 text-primary"></FontAwesomeIcon>
                          Blogs
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/testimonials" className="py-2">
                          <FontAwesomeIcon icon={faCircleQuestion} className="pe-3 text-primary"></FontAwesomeIcon>
                          Customer reviews
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.popularmaruti.com/careers" target="_blank" className="py-2">
                          <FontAwesomeIcon icon={faCircleDollarToSlot} className="pe-3 text-primary"></FontAwesomeIcon>
                          Careers
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/refer-and-earn" className="py-2">
                          <FontAwesomeIcon icon={faGift} className="pe-3 text-primary"></FontAwesomeIcon>
                          Refer & earn
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/contact-us" className="py-2">
                          <FontAwesomeIcon icon={faCircleUser} className="pe-3 text-primary"></FontAwesomeIcon>
                          Contact us
                        </NavDropdown.Item>
                      </NavDropdown>)
                  }
                </Nav >
              </Navbar.Collapse >
            </Container >
  

  )
}

export default DesktopHeader